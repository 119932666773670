import "./App.css";
import axios from "axios";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import MetaTags from "react-meta-tags";
import "bootstrap/dist/css/bootstrap.css";
import React, { useState, useEffect } from "react";
import {
  HandThumbsUp,
  HandThumbsUpFill,
  HandThumbsDown,
  HandThumbsDownFill,
  ArrowClockwise,
} from "react-bootstrap-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const API_SEARCH_URL =
  process.env.NODE_ENV === "production" && !process.env.REACT_APP_BETA_API
    ? "/api"
    : "https://lx.lexeme.dev";

const PLACEHOLDER_TEXT =
  "Type in your argument or question and hit enter to search. Press tab to try an example.";
const EXAMPLE_SEARCH_TEXTS = [
  "What constitutes ineffective assistance of counsel?",
  "Does the Fourth Amendment protect hotel rooms?",
  "justification of fair use",
  "8th Amendment proportionality test",
  "What is the difference between commercial speech and regular speech?",
  "Salerno standard",
];

function App() {
  // Set the state to the value of the input field
  const [query, setQuery] = useState("");
  const [enableNaturalAnswer, setEnableNaturalAnswer] = useState(false);
  const [results, setResults] = useState({});
  const [loading, setLoading] = useState(false);
  const [helpful, setHelpful] = useState(undefined);

  function search(
    overrideQuery = null,
    overrideNaturalAnswer = null,
    retry = false,
  ) {
    setLoading(true);

    let queryToUse = overrideQuery ?? query;
    let includeNaturalAnswer = overrideNaturalAnswer ?? enableNaturalAnswer;

    setQueryParams({ q: queryToUse, enableNaturalAnswer: enableNaturalAnswer });

    axios
      .get(API_SEARCH_URL + "/semantic_search", {
        params: {
          query: queryToUse,
          include_natural_answer: includeNaturalAnswer,
          retry: retry,
        },
      })
      .then((response) => {
        setResults(response.data);
        setHelpful(undefined);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        document.title = queryToUse + " — Lexeme Search";
      });
  }

  function onHelpfulClick(value) {
    setHelpful(value);
    axios
      .post(API_SEARCH_URL + "/feedback", {
        query: results.query,
        helpful: value ?? null,
        results: results,
      })
      .then((r) => r);
    // if (value === false) {
    //   // Let's take another shot at this!
    //   search(query, true);
    // }
  }

  function onRecommendedQueryClick(query) {
    // TODO: do some other logging here to note this was a good recommendation
    setQuery(query);
    search(query);
  }

  function getQueryParam(param) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  }

  function setQueryParams(paramsObject) {
    const urlParams = new URLSearchParams(window.location.search);
    Object.keys(paramsObject).forEach((key) => {
      urlParams.set(key, paramsObject[key]);
    });
    window.history.pushState({}, "", `?${urlParams.toString()}`);
  }

  function getRandomExample() {
    return EXAMPLE_SEARCH_TEXTS[
      Math.floor(Math.random() * EXAMPLE_SEARCH_TEXTS.length)
    ];
  }

  function onKeyDown(e) {
    // Tab
    if (e.keyCode === 9 && e.target.value === "") {
      e.preventDefault();
      const example = getRandomExample();
      e.target.value = example;
      setQuery(example);
    }
    if (e.key === "Enter" && e.target.value !== "") {
      search();
    } else {
      setQuery(e.target.value);
    }
  }

  function onQueryChange(e) {
    // Don't allow line breaks
    setQuery(e.target.value.replace(/[\r\t\n]/g, ""));
  }

  function getSearchBox() {
    return document.getElementById("input-box");
  }

  function resizeTextArea() {
    const inputBox = getSearchBox();
    inputBox.style.height = Math.max(inputBox.scrollHeight, 62) + "px";
  }

  function loadResultsForBrowserState() {
    const query = getQueryParam("q");
    const enableNaturalAnswer = getQueryParam("enableNaturalAnswer") === "true";
    if (query) {
      setQuery(query);
      setEnableNaturalAnswer(enableNaturalAnswer);
      search(query, enableNaturalAnswer);
    } else {
      getSearchBox().focus();
    }
  }

  useEffect(() => {
    window.onpopstate = () => loadResultsForBrowserState();
    loadResultsForBrowserState();
    window.addEventListener("resize", resizeTextArea);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    resizeTextArea();
  });

  const lexemeTakeTooltip = (props) => (
    <Tooltip id="lexeme-take-tooltip" {...props}>
      {enableNaturalAnswer ? "Disable" : "Enable"} Lexeme's Take (AI-powered
      summary)
    </Tooltip>
  );

  return (
    <div className="App">
      <MetaTags>
        <title>{query ? query + " — Lexeme Search" : "Lexeme Search"}</title>
        <meta
          name="description"
          content={
            query
              ? `Results for "${query}"`
              : "Research legal questions with Lexeme Search's AI-powered engine."
          }
        />
        <meta
          property="og:title"
          content={query ? query + " — Lexeme Search" : "Lexeme Search"}
        />
        <meta property="og:image" content="/logo192.png" />
      </MetaTags>
      <div className="App-header">
        <h2>Lexeme Search</h2>
      </div>
      <div className="page">
        <InputGroup className="input-wrapper">
          <Form.Control
            aria-label="Sentence Search"
            as="textarea"
            id={"input-box"}
            onKeyDown={onKeyDown}
            value={query}
            onChange={onQueryChange}
            placeholder={PLACEHOLDER_TEXT}
            className={`rounded-1 input-box ${loading ? "loading" : ""}`}
          />
          {/* Lexeme Logo. Grayed out when enableNaturalAnswer is false. */}
          <OverlayTrigger
            placement="top"
            overlay={lexemeTakeTooltip}
            trigger="hover"
          >
            <img
              src="/logo192.png"
              alt="Lexeme Logo"
              className={
                "lexeme-logo-checkbox " +
                (enableNaturalAnswer ? "" : "grayed-out")
              }
              onClick={() => setEnableNaturalAnswer(!enableNaturalAnswer)}
            />
          </OverlayTrigger>
        </InputGroup>
        {results.natural_answer && (
          <div className="natural-answer-wrapper">
            <Card className="natural-answer-card rounded-0 border-0">
              <Card.Title className="natural-answer-title">
                <img
                  src="/logo192.png"
                  alt="Lexeme Logo"
                  className="lexeme-logo"
                />
                Lexeme&rsquo;s Take
              </Card.Title>
              <Card.Text className="natural-answer-text">
                <span
                  dangerouslySetInnerHTML={{
                    __html: results.natural_answer.answer,
                  }}
                />
                <div className="natural-answer-buttons">
                  {(helpful === false || helpful === undefined) && (
                    <span
                      className="natural-answer-button helpful-yes unfilled"
                      onClick={() => onHelpfulClick(true)}
                    >
                      <HandThumbsUp />
                    </span>
                  )}
                  {helpful === true && (
                    <span
                      className="natural-answer-button helpful-yes filled"
                      onClick={() => onHelpfulClick(undefined)}
                    >
                      <HandThumbsUpFill />
                    </span>
                  )}
                  {(helpful === true || helpful === undefined) && (
                    <span
                      className="natural-answer-button helpful-no unfilled"
                      onClick={() => onHelpfulClick(false)}
                    >
                      <HandThumbsDown />
                    </span>
                  )}
                  {helpful === false && (
                    <span
                      className="natural-answer-button helpful-no filled"
                      onClick={() => onHelpfulClick(undefined)}
                    >
                      <HandThumbsDownFill />
                    </span>
                  )}
                  <span
                    className="natural-answer-button refresh"
                    onClick={() => search(query, null, true)}
                  >
                    <ArrowClockwise />
                  </span>
                </div>
              </Card.Text>
              <Card.Footer>
                {results.natural_answer.recommended_questions.map(
                  (rec_query, index) => {
                    return (
                      <span key={index} className="recommended-question-span">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                          className="recommended-question-link"
                          onClick={() => onRecommendedQueryClick(rec_query)}
                        >
                          {rec_query}
                        </a>
                      </span>
                    );
                  },
                )}
              </Card.Footer>
            </Card>
          </div>
        )}
        <div className="results-wrapper">
          {results.hits &&
            results.hits.map((hit) => {
              const fullYear = new Date(
                hit._source.case.decision_date,
              ).getFullYear();
              return (
                <a
                  target="_blank"
                  href={hit._source.page_url}
                  rel="noreferrer"
                  key={hit._id}
                  id={hit._id}
                  className="results-link"
                >
                  <Card className="results-card rounded-0 border-0">
                    <Card.Title className="results-title">
                      {" "}
                      {`${hit._source.case.case_name} (${fullYear})`}
                    </Card.Title>
                    <Card.Subtitle
                      // Putting this here as a quick hack. Opinion data is unreliable past 2015 so we just hide it.
                      className="results-subtitle"
                    >
                      {" "}
                      {fullYear < 2015 ? (
                        <span>
                          {hit._source.opinion.display_text} &middot;{" "}
                        </span>
                      ) : (
                        ""
                      )}
                      {hit._source.case.case_citation}{" "}
                    </Card.Subtitle>
                    <Card.Text className="results-text">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: hit._source.context_html,
                        }}
                      />
                    </Card.Text>
                  </Card>
                </a>
              );
            })}
          <div className="results-pad" />
        </div>
      </div>
      <div className="footer">
        <div className="footer-text">
          <a href="https://lexeme.dev" target="_blank" rel="noreferrer">
            Lexeme
          </a>{" "}
          is a semantic search engine for U.S. federal appellate courts. It is
          developed by Faiz Surani and Varun Iyer.
        </div>
      </div>
    </div>
  );
}

export default App;
